import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Section from "components/section"
import Atf from "components/atf"
import { RichText } from "prismic-reactjs"
import SEO from "components/seo"

const DataPrivacyPage = ({data}) => {

  const node = data.allPrismicDatenschutz.edges[0].node.data;

  return (
    <Layout>
      <SEO
        title={node.legal_heading.text}
        canonical
        canonicalUri="/datenschutz/"
      />
      <Atf heading={node.legal_heading.text} />
      <Section>
        <RichText
          render={node.legal_text.raw}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyQuery {
    allPrismicDatenschutz {
      edges {
        node {
          data {
            legal_heading {
              text
            }
            legal_text {
              raw
            }
          }
        }
      }
    }
  }
`

export default DataPrivacyPage
